'use strict';
import {LitElement, html, css, TemplateResult} from 'lit';
import { customElement, property } from 'lit/decorators.js';

// import myElementStyles from './my-element.css?inline';  // ?inline will inline the CSS content

@customElement('my-element')
export class MyElement extends LitElement {
    //static styles = css([myElementStyles]);
    static override styles = css`
    :host {
      display: block;
      padding: 16px;
      background-color: red;
    }
  `;

    @property()
    message = 'Hello, from Lit!';

    override render() {
        return greet(this.message);
    }
}

function greet(message: string) :TemplateResult {
    return html`<p>${message}</p>`;
}

//globalThis.customElements.define('my-element', MyElement);